<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Admins
            <v-spacer></v-spacer>
            <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary"
                       v-bind="attrs"
                       v-on="on">New admin user</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">New Admin User</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            label="Email"
                            v-model="admin.email"
                            type="email"
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            label="Password"
                            v-model="admin.password"
                            type="password"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="dialog = false"
                  >
                    Close
                  </v-btn>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="saveAdmin"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Email
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="admin in admins"
                  :key="admin.email"
              >
                <td>{{ admin.email }}</td>
                <td class="text-right">
                  <v-btn icon color="error" @click="deleteAdmin(admin.id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-container>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "Admins",
  components: {ConfirmDialog},
  data: () => ({
    admins: [],
    admin: {
      email: '',
      password: ''
    },
    loading: false,
    dialog: false
  }),
  mounted() {
    this.fetchAdmins()
  },
  methods: {
    fetchAdmins() {
      this.$axios.get('/admins').then(resp => {
        this.admins = resp.data
      })
    },
    saveAdmin() {
      this.$axios.post('/admins', this.admin).then(() => {
        this.admin = {
          email: '',
          password: ''
        }
        this.fetchAdmins()
        this.dialog = false
      })
    },
    async deleteAdmin(id) {
      if (
          await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to delete this admin user?"
          )
      ) {
        this.$axios.delete(`/admins/${id}`).then(() => {
          this.fetchAdmins()
        })
      }
    }
  }
}
</script>

<style scoped>

</style>